/*
 * Dashforge Auth Pages (Signin, Signup, etc.)
 *
 * This style is use in auth pages.
 *
 */
.content-auth {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.content-auth > .container {
  padding: 0;
}
@media (max-width: 1139px) {
  .content-auth > .container {
    max-width: none;
    padding: 0 20px;
  }
}
.content-auth > .container:first-child {
  flex: 1;
}

.content-auth-alt {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.content-auth-alt .container {
  padding: 0;
}
@media (max-width: 1139px) {
  .content-auth-alt .container {
    max-width: none;
  }
}

.sign-wrapper {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  /* align-items: center; */
  /* width: 340px; */
  /* padding-top: 30px; */
  padding-bottom: 50px;
  /* height: 500px; */
}
@media (min-width: 1200px) {
  .sign-wrapper {
    width: 340px;
  }
}

@media (min-width: 576px) {
  .sign-wrapper {
    width: 340px;
  }
}

.sign-wrapper .form-group label {
  display: block;
  margin-bottom: 5px;
}

body {
  /* background-color: "#ffffff"; */
  /* background-image: linear-gradient(0deg, #ffffff 0%, #bee2cc 46%, #73abdc 88%); */
  /* background-color: #bcc0ce26; */
  /* background: url("../img/background-login.jpg"); */
  /* background-size: 100%; */
}

.login {
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.16);
  border-radius: 10px;
  background-color: white;
}

.align-items-center {
  align-items: center;
}
